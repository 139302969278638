.product-container {
  height: 390px;
  overflow: hidden;
  border-radius: 10px !important;
  img {
    object-fit: cover;
    width: 100%;
    height: 136px;
  }
  .card-body{
    padding: 1rem 1rem 0 1rem;

    .card-title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 1rem;
    }
  }
  &.card-product {
    height: 360px;

    .card-title {
      -webkit-line-clamp: 3;
    }
  }
}

.points {
  font-size: small;
}

.redeem-btn {
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 18px;
}

.product-info-container-tab {
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.14);
  padding: 10px;
}
.tab-content {
  padding-inline: 10px;
}

.product-order-container {
  @media (max-width: 575px) {
    margin-block: 10px;
  }
}
.product-multiselect-container {
  @media (max-width: 575px) {
    margin-block: 10px;
  }
}
