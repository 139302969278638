.challange-info-container-tab{
    border-radius: 10px !important;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.14);
    padding: 10px;
}
.tab-content{
    padding-inline: 10px;
}
  
.card-description{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 3px;
    font-size: .8rem;
}