.news-container {
  border-radius: 10px !important;
  height: 360px;
  img {
    object-fit: cover;
    width: 100%;
    height: 150px;
  }
  &.no-btn{
    height: 320px;
  }
  .card-body{
    padding: 1rem 1rem 0 1rem;

    .card-title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 1rem;
    }
  }
}

.news-paragraph-collapsed:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 5vh, #f8f9fa);
}

.news-paragraph-collapsed {
  max-height: 20vh;
  overflow: hidden;
}

.see-more {
  text-align: right;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 10%;
}
